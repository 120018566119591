<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: "App",
};
</script>

<style>
[v-cloak] {
    display: none;
}

#app {
    /*font-family: 'Avenir', Helvetica, Arial, sans-serif;*/
    font-family: -apple-system, "Helvetica Neue", "Helvetica", "Arial",
    "PingFang SC", "Hiragino Sans GB", "STHeiti", "Microsoft YaHei",
    "Microsoft JhengHei", SimSun, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
    /*background-color: #f9f9f9;*/
    /*min-width: 320px;*/
    /*max-width: 1200px;*/
}
</style>