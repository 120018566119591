import Vue from "vue";
import axios from "axios";
// 创建 axios 实例
const http = axios.create({
    baseURL: "/api", // 如果所有请求都以 '/api' 作为前缀，可以在这里设置基础URL
});

// 请求拦截器
http.interceptors.request.use(
    function (config) {
        // 在发送请求之前做些什么，例如添加认证头部
        const token = localStorage.getItem("token");
        if (token) {
            config.headers.Authorization = token;
        }
        return config;
    },
    function (error) {
        // 对请求错误做些什么
        Vue.prototype.$message.error("请求发送失败: " + error.message);
        return Promise.reject(error);
    }
);

// 响应拦截器
http.interceptors.response.use(
    function (response) {
        // 对响应数据做点什么
        return response;
    },
    function (error) {
        // 对响应错误做些什么
        if (error) {
            const status = error.response.status;
            const errorMessage = error.response.data.message;
            console.log("error: " + status);
            console.log("response: " + errorMessage);
            switch (status) {
                case 401:
                case 403:
                case 410:
                    localStorage.removeItem("token");
                    Vue.prototype.$login.show(true);
                    Vue.prototype.$message.error(errorMessage);
                    break;
                default:
                    Vue.prototype.$message.error(errorMessage);
                    break;
            }
        } else {
            // 如果 error.response 不存在，可能是网络或其他错误导致
            Vue.prototype.$message.error("网络错误或服务器无响应");
        }
    }
);

export default http;
