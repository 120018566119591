import Vue from "vue";
import LoginComponent from "../page/component/MyLogin.vue";
import ElementUI from "element-ui";

Vue.use(ElementUI);
// 创建构造器
const LoginConstructor = Vue.extend(LoginComponent);

// 创建实例，并挂载到一个元素上
const instance = new LoginConstructor({
    data: {
        dialogVisible: false,
    },
    methods: {
        show(flag) {
            this.dialogVisible = flag;
        },
    },
});

// 挂载到一个元素上
instance.$mount();

// 添加到body
document.body.appendChild(instance.$el);

// 导出函数
export default function install() {
    Vue.prototype.$login = instance;
}
