<template>
    <el-dialog
            title="登录"
            :visible.sync="dialogVisible"
            @close="closeDialog"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
    >
        <el-tabs v-model="activeName" @tab-click="handleClick" v-if="dialogVisible">
            <el-tab-pane label="登录" name="first">
                <el-form ref="form1" :rules="rules1" :model="form1">
                    <el-form-item prop="name">
                        <el-input
                                v-model="form1.name"
                                placeholder="请输入用户名/手机号/邮箱"
                                clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="pwd">
                        <el-input
                                v-model="form1.pwd"
                                type="password"
                                placeholder="请输入密码"
                                clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="login('form1')" class="submit">登录</el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="快速登录" name="second">
                <el-form ref="form2" :rules="rules2" :model="form2">
                    <el-form ref="form22" :rules="rules22" :model="form2">
                        <el-form-item prop="name">
                            <el-input
                                    v-model="form2.name"
                                    placeholder="请输入手机号/邮箱"
                                    clearable
                            ></el-input>
                        </el-form-item>
                    </el-form>
                    <el-form-item prop="record">
                        <el-input
                                v-model.number="form2.record"
                                placeholder="请输入验证码"
                                clearable
                        >
                            <el-button @click="sendCode" slot="append">{{ send }}</el-button>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="login('form2')" class="submit">登录</el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="注册" name="third">
                <el-form ref="form3" :model="form3" :rules="rules3">
                    <input type="password" style="display: none"/>
                    <el-form-item prop="phone">
                        <el-input
                                v-model="form3.phone"
                                placeholder="请输入手机号/邮箱"
                                clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="nick">
                        <el-input
                                v-model="form3.nick"
                                placeholder="请输入昵称（选填）"
                                clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item prop="name">
                        <el-input
                                v-model="form3.name"
                                placeholder="请输入登录名（同时作为主页地址）"
                                onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
                                clearable
                        ></el-input>
                    </el-form-item>
                    <div style="text-align: left; margin-bottom: 10px">
                        主页地址: {{ host }}/{{ form3.name }}
                    </div>
                    <el-form-item prop="pwd">
                        <el-input
                                v-model="form3.pwd"
                                type="password"
                                placeholder="请输入密码"
                                clearable
                        ></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="register('form3')" class="submit"
                        >注册
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <div>
                <el-divider content-position="center">社交账号登录</el-divider>
                <a href="javascript:void(0);" @click="getCode">
                    <svg
                            t="1599540370010"
                            class="icon"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="2438"
                            width="35"
                            height="35"
                            style="margin: 0 auto; display: block"
                    >
                        <path
                                d="M669.3 369.4c9.8 0 19.6 0 29.4 1.6C671 245.2 536.9 152 383.2 152 211.6 152 71 269.7 71 416.8c0 85 45.8 156.9 124.2 210.9l-31.1 93.2L273.6 667c39.2 8.2 70.3 16.3 109.5 16.3 9.8 0 19.6 0 31.1-1.6-6.5-21.3-9.8-42.5-9.8-65.4 0.1-135.7 116.2-246.9 264.9-246.9z m-168.4-85c24.5 0 39.2 16.3 39.2 39.2 0 22.9-16.3 39.2-39.2 39.2-24.5 0-47.4-16.4-47.4-39.2 0-24.5 24.6-39.2 47.4-39.2z m-216.3 73.1c-24.7 0-47.8-16.2-47.8-38.8 0-24.3 24.7-38.8 47.8-38.8s39.5 16.2 39.5 38.8c0.1 22.7-16.4 38.8-39.5 38.8z"
                                fill="#1a5f5f"
                                p-id="2439"
                        />
                        <path
                                d="M953.8 613c0-125.9-124.2-227.2-264.8-227.2-148.8 0-266.5 103-266.5 227.2 0 125.9 117.7 227.2 266.5 227.2 31.1 0 62.1-8.2 93.2-16.3l85 47.4-22.9-78.5c62.1-47.4 109.5-109.5 109.5-179.8z m-351.5-39.2c-14.7 0-31.1-14.7-31.1-31.1 0-14.7 16.3-31.1 31.1-31.1 22.9 0 39.2 16.3 39.2 31.1 0 16.4-14.7 31.1-39.2 31.1z m178-7.6c-14.8 0-31.3-14.6-31.3-30.7 0-14.6 16.5-30.7 31.3-30.7 23.1 0 39.5 16.2 39.5 30.7 0 16.2-16.4 30.7-39.5 30.7z"
                                fill="#1a5f5f"
                                p-id="2440"
                        />
                    </svg>
                </a>
            </div>
        </el-tabs>
    </el-dialog>
</template>

<script>
const reg1 = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
const reg2 = /^1[3456789]\d{9}$/;
const reg3 = /^[0-9a-zA-Z_]{1,}$/;

export default {
    data() {
        const validatePass = (rule, value, callback) => {
            if (!(reg2.test(value) || reg1.test(value))) {
                callback(new Error("手机/邮箱格式不正确"));
            } else {
                callback();
            }
        };
        const validatePass1 = (rule, value, callback) => {
            if (reg2.test(value) || reg1.test(value)) {
                callback(new Error("不能为手机或者邮箱格式"));
            } else if (!reg3.test(value)) {
                callback(new Error("只能输入字母数字下划线"));
            } else if (value.length < 3 || value.length > 32) {
                callback(new Error("只能输入3-32位的用户名"));
            } else {
                callback();
            }
        };
        const validatePass2 = (rule, value, callback) => {
            let param = {};
            if (reg1.test(value)) {
                param.email = value;
            } else if (reg2.test(value)) {
                param.phone = value;
            } else {
                param.username = value;
            }
            this._get("/user/select", param).then((res) => {
                if (res && res.data.list && res.data.list.length > 0) {
                    callback(new Error("用户已存在"));
                }
                callback();
            });
        };
        return {
            host: "",
            dialogVisible: false,
            activeName: "first",
            form1: {
                name: "",
                pwd: "",
            },
            form2: {
                name: "",
                record: "",
            },
            form3: {
                phone: "",
                name: "",
                pwd: "",
                nick: "",
            },
            rules1: {
                name: [
                    {
                        required: true,
                        message: "请输入用户名/手机号/邮箱",
                        trigger: "blur",
                    },
                ],
                pwd: [{required: true, message: "请输入密码", trigger: "blur"}],
            },
            rules2: {
                name: [
                    {required: true, message: "请输入手机号/邮箱", trigger: "blur"},
                    {required: true, trigger: "blur", validator: validatePass},
                ],
                record: [
                    {required: true, message: "请输入验证码"},
                    {type: "number", message: "验证码必须为数字值"},
                ],
            },
            rules22: {
                name: [
                    {required: true, message: "请输入手机号/邮箱", trigger: "blur"},
                    {required: true, trigger: "blur", validator: validatePass},
                ],
            },
            rules3: {
                phone: [
                    {required: true, message: "请输入手机号/邮箱", trigger: "blur"},
                    {required: true, trigger: "blur", validator: validatePass},
                    {required: true, trigger: "blur", validator: validatePass2},
                ],
                name: [
                    {
                        required: true,
                        message: "请输入用户名（同时作为主页地址）",
                        trigger: "blur",
                    },
                    {required: true, trigger: "blur", validator: validatePass1},
                    {required: true, trigger: "blur", validator: validatePass2},
                ],
                pwd: [{required: true, message: "请输入密码", trigger: "blur"}],
            },
            send: "发送",
        };
    },
    created() {
        this.host = location.host;
    },
    methods: {
        show(flag) {
            this.dialogVisible = flag;
        },
        handleClick(tab, event) {
            console.log(tab, event);
        },
        active(name) {
            this.activeName = name;
        },
        sendCode() {
            this.$refs["form22"].validate((valid) => {
                if (valid && this.send === "发送") {
                    let lastTime = 59;
                    this.send = lastTime + "s";
                    let timer = setInterval(() => {
                        if (lastTime >= 0) {
                            this.send = lastTime + "s";
                            lastTime--;
                        } else {
                            window.clearInterval(timer);
                            this.send = "发送";
                        }
                    }, 1000);
                }
                let url = "";
                if (reg2.test(this.form2.name)) {
                    url = "/sms/sentMsgCode" + "&phone=";
                } else if (reg1.test(this.form2.name)) {
                    url = "/sms/sentMailCode" + "&mailTo=";
                }
                if (reg1.test(this.form2.name) || reg2.test(this.form2.name)) {
                    this._post(url + this.form2.name).then((res) => {
                        this.$message.info("发送成功");
                    });
                }
            });
        },
        login(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let param = {};
                    if (formName === "form1") {
                        if (reg1.test(this.form1.name)) {
                            param.email = this.form1.name;
                        } else if (reg2.test(this.form1.name)) {
                            param.phone = this.form1.name;
                        } else {
                            param.username = this.form1.name;
                        }
                        this.form1.pwd = this.encryptor(this.form1.pwd);
                        param.password = this.form1.pwd;
                    } else if (formName === "form2") {
                        if (reg1.test(this.form2.name)) {
                            param.email = this.form2.name;
                        } else if (reg2.test(this.form2.name)) {
                            param.phone = this.form2.name;
                        }
                        param.vailCode = this.form2.record;
                    }
                    this._post("/user/login", param).then((res) => {
                        if (res) {
                            localStorage.setItem("token", res.data.token);
                            this.dialogVisible = false;
                        }
                    });
                }
            });
        },
        register(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let param = {};
                    if (formName === "form3") {
                        if (reg1.test(this.form3.phone)) {
                            param.email = this.form3.phone;
                        } else if (reg2.test(this.form3.phone)) {
                            param.phone = this.form3.phone;
                        }
                        param.username = this.form3.name;
                        param.nickName = this.form3.nick;
                        param.password = this.encryptor(this.form3.pwd);
                    }
                    this._post("/user/register", param).then((res) => {
                        if (res) {
                            localStorage.setItem("token", res.data.token);
                            this.dialogVisible = false;
                        }
                    });
                }
            });
        },
        // 加密密码
        encryptor(password) {
            return password;
        },
        closeDialog() {
            if (localStorage.getItem("token")) {
                location.reload();
            }
        },
        getCode() {
            localStorage.setItem("url", location.href);
            let type = navigator.userAgent.toLowerCase().includes("micromessenger")
                ? "SSO_PHONE"
                : "SSO_PC";
            this._get("/oauth/select?type=" + type).then((res) => {
                if (type === "SSO_PC") {
                    location.href = res.data.pcLogin;
                } else {
                    location.href = res.data.phoneLogin;
                }
            });
        },
    },
};
</script>

<style lang="scss">
.el-tabs__nav {
  width: 100%;
}

.el-tabs__item {
  width: 33%;
  text-align: center;
}

.el-button {
  background-color: #1a5f5f;
  color: #ffffff;
}

.el-button:focus,
.el-button:hover {
  background-color: #1a5f5f;
  color: #ffffff;
  border-color: #3b8070;
}

.submit {
  width: 100%;
  background-color: #1a5f5f;
}

.el-dialog {
  width: 380px;
  margin: auto;
  text-align: center;
}

.el-dialog__body {
  width: 75%;
  margin: auto;
}

@media (max-width: 767px) {
  .el-dialog {
    width: 100%;
  }
}
</style>
